<template>
  <div class="homePage">
    <div class="home_top">
      <div class="home_top_left">
        <div
          class="home_left_b"
          @click="handleHome"
        >沈阳先锋管理云平台</div>
        <div class="home_left_f">
          <a-select
            placeholder="请选择地区"
            v-model="queryParam1.provinceCode"
            show-search
            option-filter-prop="children"
            :filter-option="filterOption"
            @change="handleArea"
            allow-clear
          >
            <a-select-option
              v-for="(d, index) in treeList"
              :key="index"
              :value="d.fullName"
            >{{ d.fullName }}</a-select-option>
          </a-select>
          <a-select
            placeholder="请选择公司/部门"
            v-model="queryParam1.tenantId"
            show-search
            option-filter-prop="children"
            :filter-option="filterOption"
            allow-clear
            @change="handleChangeDev"
            v-if="role !== 'GS' && role !== 'YG'"
          >
            <a-select-option
              v-for="(d, index) in companyList"
              :key="index"
              :value="d.id"
            >{{ d.name }}</a-select-option>
          </a-select>
          <a-select
            placeholder="请选择设备"
            v-model="queryParam1.devId"
            show-search
            option-filter-prop="children"
            :filter-option="filterOption"
            @change="handleChangeBu"
            allow-clear
          >
            <a-select-option
              v-for="(d, index) in devListNum"
              :key="index"
              :value="d.id"
            >{{ d.devName }}</a-select-option>
          </a-select>
          <!-- <a-input
            placeholder="请输入设备名称"
            v-model="queryParam1.devName"
          /> -->
          <a-button
            type="primary"
            @click="handleSeach"
          >
            搜索
          </a-button>
          <a-button
            type="primary"
            class="clear"
            @click="handleClear1"
          >
            清除
          </a-button>
        </div>
        <!-- <div style="color:#fff;">111</div> -->
      </div>
      <div class="home_top_right">
        <img
          src="~@/assets/home/nao.png"
          alt=""
        >
        {{ date }}
        <div>
          {{ week }}
        </div>
        {{ dates }}
      </div>
    </div>
    <div class="home_cent">
      <div class="home_cent_l">
        <div class="home_map">
          <GdMap ref="map"></GdMap>
        </div>
        <div class="home_line">
          <div class="home_line_t">
            <div class="home_line_t_l">
              <img
                src="~@/assets/home/biao.png"
                alt=""
              >
              <div class="home_line_t_n">数据查询</div>
              <a-select
                placeholder="预警车辆统计"
                v-model="queryParam.type"
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
                allow-clear
              >
                <a-select-option
                  v-for="(d, index) in options"
                  :key="index"
                  :value="d.id"
                >{{ d.name }}</a-select-option>
              </a-select>
              <div>
                <a-checkbox-group
                  v-model="checkedList"
                  :options="plainOptions"
                  @change="onChange"
                />
              </div>
            </div>
            <div class="time_sou">
              <a-range-picker
                v-model="value1"
                @change="handleTime"
                dropdownClassName="rangePickerIceGai"
              />
              <a-button
                type="primary"
                class="primary1"
                @click="GuoTj"
              >
                搜索
              </a-button>
              <a-button
                type="primary"
                class="clear primary1"
                @click="handleClear"
              >
                清除
              </a-button>
              <img
                src="~@/assets/home/you.png"
                alt=""
              >
            </div>
          </div>
          <div class="home_line_l_y">
            <lineChart :chartData="chartData"></lineChart>
          </div>
        </div>
      </div>
      <div class="home_cent_r">
        <div class="cent_r_head">设备实时预警</div>
        <div class="cent_r_c">
          <div
            class="cent_r_i"
            @click="handleDev(item)"
            v-for="(item, index) in devList"
            :key="index"
          >
            <div class="cent_r_i_h">
              <div class="r_i_l">
                <div class="r_i_l_1">{{ item.devName }}</div>
                <div class="r_i_l_2">{{ item.earlyStatus == 1 ? '超速预警' :
                  item.earlyStatus == 2 ? '声音预警' : item.earlyStatus == 3 ? '灯光预警' : item.earlyStatus == 4 ? '电量预警' : '无预警'
                }}
                </div>
              </div>
              <div class="r_i_r">时间:{{ item.nowCarTime }}</div>
            </div>
            <div class="cent_r_i_c">
              <div class="cent_r_i_c_o">
                序列号：{{ item.devCode }}
                <div class="cent_r_i_c_d">{{ item.addressName }}</div>
              </div> <img
                src="~@/assets/home/you1.png"
                alt=""
              >
            </div>
          </div>
        </div>
        <div class="cent_r_head">设备数</div>
        <div class="home_line_l_s">
          <bingChart :chartData="chartData1"></bingChart>
          <div class="home_DEV">
            <div>{{ devNumAll }}</div>
            共计(台)
          </div>
        </div>
        <div class="cent_r_head"><span class="span">设备排行</span> <a-select
            placeholder="预警车辆排序"
            v-model="queryParam2.type"
            show-search
            option-filter-prop="children"
            :filter-option="filterOption"
            allow-clear
            @change="getPh"
          >
            <a-select-option
              v-for="(d, index) in option"
              :key="index"
              :value="d.id"
            >{{ d.name }}</a-select-option>
          </a-select>
        </div>
        <div class="cent_P">
          <div
            class="cent_p_i"
            v-for="(item, index) in devPai"
            :key="index"
            @click="handleGong(item)"
          >
            <div class="cent_P_t">
              <div class="cent_P_t_l">
                <div class="p_t_1">{{ item.devName }}</div>
                <div class="p_t_2">{{ item.tenantName }}</div>
              </div>
              <div class="p_t_3">{{ item.addressName }}</div>
            </div>
            <div class="cent_P_f">
              <div class="cent_P_f_1"><a-progress
                  trailColor="#62676D"
                  strokeColor="#489BFB"
                  :percent="(item.numAll / item.numAllDev) ? item.numAll / item.numAllDev : 0"
                  :show-info="false"
                /></div>
              {{ item.numAll ? item.numAll : 0 }}
              <span>{{ queryParam2.type == 6 ? '%' : '台' }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import GdMap from '@/components/MAP/index.vue'
import lineChart from './Charts/lineChart.vue'
import bingChart from './Charts/bing.vue'
import { getRouters } from '@/api/menu'
import { devList, devPx, devNum, devYj, carTj, devJk, sysTree, getDevice } from '@/api/home'
import { listCompany } from '@/api/project/company'
import { mapGetters } from 'vuex'
var timerNum
export default {
  components: {
    GdMap,
    lineChart,
    bingChart
  },
  computed: {
    ...mapGetters([
      'roles',
      'tenantIds'
    ])
  },
  data () {
    return {
      codeUrl: '',
      path: '',
      value1: [],
      devListNum: [],
      plainOptions: [
        { label: '时', value: 0 },
        { label: '日', value: 1 },
        { label: '周', value: 2 },
        { label: '月', value: 3 },
        { label: '年', value: 4 }
      ],
      date: new Date(),
      dates: new Date(),
      chartData1: {},
      queryParam: {
        typeTime: 0,
        createBeginTime: '',
        createEndTime: '',
        provinceCode: '',
        type: 0,
        devId: ''
      },
      queryParam1: {
        provinceCode: undefined,
        tenantId: undefined,
        devName: '',
        devId: undefined
      },
      queryParam2: {
        type: 0,
        devName: ''
      },
      devNumAll: '',
      agentList: [],
      treeList: [],
      devList: [],
      chartData: {},
      week: '',
      option: [{ id: 0, name: '预警车辆排序' }, { id: 1, name: '超速车辆排序' }, { id: 2, name: '未超速车辆排序' }, { id: 3, name: '车流量排序' }, { id: 4, name: '设备在线率排序' }, { id: 5, name: '设备故障率排序' }, { id: 6, name: '设备电量率排序' }],
      options: [{ id: 0, name: '预警车辆统计' }, { id: 1, name: '超速车辆查询' }, { id: 2, name: '未超速车辆查询' }, { id: 3, name: '车流量查询功能' }],
      checkedList: [0],
      checked: 0,
      indeterminate: true,
      checkAll: false,
      devPai: [],
      role: '',
      companyList: []
    }
  },
  mounted () {
    const that = this
    this.timer = setInterval(function () {
      that.date = moment().format('YYYY年MM月DD日')
      that.dates = moment().format('HH:mm:ss')
      const week = moment().day()
      // console.log(week, 'weekweekweek')
      // switch (week) {
      //   case 1:
      //     return '周一'
      //   case 2:
      //     return '周二'
      //   case 3:
      //     return '周三'
      //   case 4:
      //     return '周四'
      //   case 5:
      //     return '周五'
      //   case 6:
      //     return '周六'
      //   case 0:
      //     return '周日'
      // }
      let weeks
      if (week === 1) {
        weeks = '周一'
      } else if (week === 2) {
        weeks = '周二'
      } else if (week === 3) {
        weeks = '周三'
      } else if (week === 4) {
        weeks = '周四'
      } else if (week === 5) {
        weeks = '周五'
      } else if (week === 6) {
        weeks = '周六'
      } else if (week === 7) {
        weeks = '周日'
      }
      that.week = weeks
    })

    // this.chartData = {
    // }
  },
  beforeDestroy: function () {
    if (this.timer) {
      clearInterval(this.timer)
    }
    // if (timerNum) {
    // }
    clearInterval(timerNum)
  },
  created () {
    console.log(this.$route, '大大大大大')
    this.role = this.roles[0]
    // 向后端请求路由数据
    getRouters().then(res => {
      // 路由菜单分离，路由全部为二级，解决多级菜单缓存问题
      this.path = '/' + res.data[0].path
      console.log(res.data, '测试萨达萨达')
    })
    // 查询过车统计
    this.GuoTj()
    this.getDevNum()
    this.getYj()
    this.getPh()
    if (this.role === 'GS') {
      this.queryParam.tenantId = this.tenantIds
      this.queryParam1.tenantId = this.tenantIds
    } else if (this.role === 'YG') {

    } else {
      listCompany().then(res => {
        this.companyList = res.data
      })
    }
    this.getDev()
    sysTree().then(res => {
      this.treeList = res.data
    })
    timerNum = setInterval(() => {
      // 查询过车统计
      this.GuoTj()
      this.getDevNum()
      this.getYj()
      // this.getPh()
      if (this.role === 'GS') {
        this.queryParam.tenantId = this.tenantIds
        this.queryParam1.tenantId = this.tenantIds
      } else if (this.role === 'YG') {

      } else {
        listCompany().then(res => {
          this.companyList = res.data
        })
      }
    }, 30000)
  },
  methods: {
    handleDev (item) {
      console.log(item, '大大大大大')
      this.$router.push({ name: 'Device', params: { devCode: item.devCode } })
    },
    // 点击进入道公司
    handleGong (item) {
      console.log(item, '嘟嘟嘟嘟')
      if (this.role === 'GS' || this.role === 'YG') {
      } else {
        this.$router.push({ name: 'Company', params: { tenantName: item.tenantName } })
      }
    },
    // 选择地址
    handleArea (e) {
      this.queryParam.provinceCode = e
    },
    // 选择设备
    handleChangeBu (e) {
      this.queryParam.devId = e
    },
    // 选择部门查询设备列表
    handleChangeDev (e) {
      console.log(e, '滴滴滴滴滴滴')
      this.queryParam.tenantId = e
      this.getDev()
    },
    // 搜索地图
    handleSeach () {
      this.$nextTick(() => {
        this.$refs.map.getMap(this.queryParam1, 1)
      })
      this.getDevNum()
      this.getYj()
      this.GuoTj()
    },
    // 查询设备
    getDev () {
      getDevice(this.queryParam1).then(res => {
        console.log(res, '大大大大大===')
        this.devListNum = res.data
      })
    },
    // 设备排行
    getPh () {
      devPx(this.queryParam2).then(res => {
        this.devPai = res.data
      })
    },
    // 实时预警
    getYj () {
      devYj(this.queryParam1).then(res => {
        this.devList = res.data
      })
    },
    // 查询设备数据
    getDevNum () {
      console.log('测试是否进入设备====')
      const query = {
        arr: []
      }
      devNum(this.queryParam1).then(res => {
        this.devNumAll = res.data.devNumAll
        res = res.data.list
        res.forEach(v => {
          if (v.onLine === 1) {
            query.arr.push({ value: v.devNum, name: '在线', itemStyle: { color: '#489bfb' } })
          } else if (v.onLine === 2) {
            query.arr.push({ value: v.devNum, name: '故障', itemStyle: { color: '#edc910' } })
          } else {
            query.arr.push({ value: v.devNum, name: '离线', itemStyle: { color: '#bbe7f2' } })
          }
        })
        console.log(this.chartData1, 'this.chartData1this.chartData1')
        this.chartData1 = query
      })
    },
    handleTime (e) {
      console.log(e, '滴滴滴滴滴滴')
      this.queryParam.createBeginTime = e[0].format('YYYY-MM-DD')
      this.queryParam.createEndTime = e[1].format('YYYY-MM-DD')
    },
    onChange (e) {
      console.log(e, '滴滴滴滴滴滴')
      // this.indeterminate = !!checkedList.length && checkedList.length < plainOptions.length
      // this.checkAll = checkedList.length === plainOptions.length
      if (e.length > 1) {
        console.log(this.checked, 'ddddddsss')
        console.log(e, '大大大大大')
        const num = e.filter(v => v !== this.checked)[0]
        console.log(num, '滴滴滴滴滴滴')
        this.checked = num
        this.queryParam.typeTime = num
        this.checkedList = [num]
        this.GuoTj()
        // const num = e[1]
        // if (num === '时') {
        //   this.queryParam.typeTime = 0
        // } else if (num === '日') {
        //   this.queryParam.typeTime = 1
        // } else if (num === '日') {
        //   this.queryParam.typeTime = 2
        // } else if (num === '周') {
        //   this.queryParam.typeTime = 3
        // } else if (num === '月') {
        //   this.queryParam.typeTime = 4
        // } else if (num === '年') {
        //   this.queryParam.typeTime = 5
        // }
        // e.splice(0, 1)
      } else if (e.length === 0) {
        this.checked = [this.queryParam.typeTime]
        this.checkedList = [this.queryParam.typeTime]
      }
    },
    // 点击清除统计
    handleClear () {
      this.queryParam = {}
      this.value1 = []
      this.queryParam.typeTime = 0
      this.checkedList = ['时']
      this.GuoTj()
    },
    // 点击地图上的清楚统计
    handleClear1 () {
      this.queryParam1 = {}
      this.devListNum = []
      this.queryParam.tenantId = this.tenantIds
      if (this.role === 'GS') {
        this.queryParam.tenantId = this.tenantIds
        this.queryParam1.tenantId = this.tenantIds
        this.getDev()
      }
      this.$nextTick(() => {
        this.$refs.map.getMap(this.queryParam1, 2)
      })
    },
    // 查询所以的设备
    GuoTj () {
      const query = {
        numAll: [],
        times: []
      }
      carTj(this.queryParam).then(res => {
        console.log(res, '热顶顶顶顶')
        res = res.data
        res.forEach(v => {
          query.times.push(v.times)
          if (v.numAll) {
            query.numAll.push(v.numAll)
          } else {
            query.numAll.push(0)
          }
        })
        this.chartData = query
      })
    },
    handleHome () {
      // console.log(this.$router, '大大大大大')
      this.$router.push({ path: this.path })
    },
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.container {
  padding: 0 !important;
}

.homePage {
  width: 100%;
  height: 100vh;
  background: url(../assets/home/bei.png) no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
  padding: 15px 10px 15px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .home_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .home_top_left {
      padding-left: 18px;
      display: flex;
      align-items: center;

      .home_left_b {
        font-size: 37px;
        font-family: SourceHanSansCN-Bold;
        line-height: 44px;
        color: #FFFFFF;
        margin-right: 48px;
        cursor: pointer;
        text-shadow: -1.8369701465288538e-16px 3px 4px rgba(54, 180, 205, 0.6);
      }

      .home_left_f {
        display: flex;
        align-items: center;

        /deep/ .ant-btn {
          margin-left: 24px;
          border: none !important;
        }
      }

      .clear {
        background: #86878c !important;
      }
    }

    .home_top_right {
      color: #fff;
      display: flex;
      align-items: center;

      img {
        width: 16px;
        height: 16px;
        margin-right: 8px;
        font-size: 16px;
      }

      div {
        margin: 0 10px;
      }
    }
  }

  .home_cent {
    // width: 100%;
    display: flex;
    justify-content: space-between;
    flex: 1;
    width: 100%;
    // align-items: center;
    // background: red;

    .home_cent_l {
      // height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 72%;
      height: 100%;

      // background: red;
      .home_map {
        width: 100%;
        height: 68%;
        // height: 100% !important;
        // background: red;
      }
    }

    .home_cent_r {
      // flex: 1;
      // background: red;
      // margin-left: 25px;
      width: 27%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .cent_r_head {
        width: 484px;
        height: 55px;
        display: flex;
        justify-content: space-between;
        // align-items: center;
        color: #fff;
        padding-left: 24px;
        line-height: 30px;
        font-size: 18px;
        margin-bottom: 5px;
        background: url(../assets/home/bei1.png) no-repeat;
        background-size: 100% 100%;
        padding-top: 7px;
        // background: linear-gradient(90deg, rgba(15, 95, 165, 0.68) 0%, rgba(14, 86, 150, 0.55) 19%, rgba(8, 48, 83, 0) 100%);
      }

      .cent_P {
        width: 474px;
        height: 21.5vh;
        background: rgba(194, 250, 255, 0.04);
        overflow-y: auto;
        padding: 20px 16px 0;

        .cent_p_i {
          margin-bottom: 15px;
          cursor: pointer;
        }

        /deep/.ant-progress-inner {
          background: #62676D !important;
        }

        .cent_P_f {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #A3ACBC;
          font-size: 14px;

          .cent_P_f_1 {
            width: 329px;
          }
        }

        .cent_P_t {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: rgba(183, 207, 252, 0.46);
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          margin-bottom: 5px;

          .cent_P_t_l {
            display: flex;
            align-items: center;
            color: #fff;
            font-size: 16px;
            font-family: SourceHanSansCN-Regular;
            line-height: 24px;

            .p_t_1 {
              max-width: 120px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              margin-right: 20px;
            }

            .p_t_2 {
              max-width: 135px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              // margin-right: 20px;
            }
          }
        }

        &::-webkit-scrollbar {
          width: 10px !important;
          height: 10px !important;
          display: none !important;
        }
      }

      .cent_r_c {
        width: 474px;
        height: 200px;
        background: rgba(194, 250, 255, 0.04);
        overflow-y: auto;
        padding: 18px 16px 0 16px;
        margin-bottom: 16px;

        &::-webkit-scrollbar {
          width: 10px !important;
          height: 10px !important;
          display: none !important;
        }

        .cent_r_i {
          margin-bottom: 22px;
          cursor: pointer;

          .cent_r_i_h {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .r_i_l {
              display: flex;
              align-items: center;
              font-family: SourceHanSansCN-Regular;
              line-height: 24px;
              color: #FFFFFF;

              .r_i_l_1 {
                // width: 120px;
                max-width: 120px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                font-size: 16px;
              }

              .r_i_l_2 {
                width: 64px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: rgba(255, 87, 87, 0.2);
                opacity: 1;
                border-radius: 4px;
                font-size: 12px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                line-height: 24px;
                color: #FF7878;
                margin-left: 18px;
              }
            }

            .r_i_r {
              font-size: 12px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: rgba(183, 207, 252, 0.6);
            }

          }

          .cent_r_i_c {
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            color: #A3ACBC;
            margin-top: 10px;

            .cent_r_i_c_o {
              display: flex;
              align-items: center;
              color: #A3ACBC;

              .cent_r_i_c_d {
                width: 250px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-left: 10px;
              }
            }

            img {
              width: 6px;
              height: 12px;
            }
          }
        }
      }
    }

    .home_line {
      // margin-top: 40px;
      // height: 38vh;
      // padding-left: 16px;
      width: 100%;
      height: 31%;
      background: #081e3d;
      background: rgba(15, 123, 228, 0.15);
      display: flex;
      flex-direction: column;

      .home_line_t {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // padding: 10px 0;
        padding: 0 10px;
        border-top: 1px solid #103665;
        border-bottom: 1px solid #103665;

        /deep/.ant-checkbox-group-item {
          color: #fff !important;
        }

        /deep/.ant-checkbox-inner {
          background: #031228 !important;
          border: 1px solid #489bfb !important;
          color: #fff !important;
        }

        .home_line_t_l {
          display: flex;
          align-items: center;

          img {
            width: 25px;
            height: 25px;
          }

          .home_line_t_n {
            font-size: 18px;
            font-family: PangMenZhengDao;
            font-weight: 400;
            line-height: 18px;
            color: #F8F8F9;
            margin-left: 10px;
            margin-right: 24px;
          }
        }
      }

      .home_line_l_y {
        flex: 1;
        padding-top: 10px;
      }
    }
  }

  /deep/.ant-select-selection--single {
    width: 160px !important;
    background: #0f2950 !important;
    border: 1px solid #489bfb;
    margin-right: 24px;

    .ant-select-arrow-icon {
      // border: 1px solid #489bfb;
      color: #489bfb;
    }
  }

  /deep/.ant-select-selection-selected-value {
    color: #fff !important;
  }

  /deep/.ant-input {
    background: #0f2950 !important;
    border: 1px solid #489bfb;
    color: #fff;
  }
}

.time_sou {
  display: flex;
  align-items: center;
  padding: 10px;

  img {
    margin-left: 48px;
    width: 102px;
    height: 9px;
  }

  .primary1 {
    margin-left: 16px;
  }

  .clear {
    background: #86878c;
    border: none;
  }

  /deep/.ant-input {
    width: 250px !important;
  }

  /deep/.ant-calendar-range-picker-separator {
    color: #fff;
  }
}

.home_line_l_s {
  height: 23vh;
  position: relative;

  .home_DEV {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #F8F8F9;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    text-align: center;

    div {
      font-size: 22px;
      font-family: PangMenZhengDao;
      font-weight: 400;
      line-height: 18px;
    }
  }
}

// /deep/.ant-progress-bg {
//   background: #62676D !;
// }

.handleHome {}

// /deep/.ant-calendar-next-decade-btn::before {
//   width: 120px !important;
//   height: 120px !important;
// }
</style>
