<!--
 * @Descripttion: 高德地图
 * @version: 1.0.0
 * @@Company: ZZCYI
 * @Author: 刘
 * @Date: 2020-08-03 15:39:14
 * @LastEditors: 王
 * @LastEditTime: 2021-01-07 15:24:11
 * @Update Descripttion:
-->
<template>
  <div class="amap-wrapper">
    <!-- <el-amap
      class="amap-box"
      style="width:100%;height: calc(100vh - 120px);"
      :vid="'amap-vue-search'"
      ref="map"
      :center="center"
      :zoom="zoom"
      :events="events"
    >
      <el-amap-marker
        v-for="(marker,index) in markers"
        :position="marker"
        :key="index"
      ></el-amap-marker>
    </el-amap> -->
    <el-amap
      class="amap-box"
      :events="events"
      :zoom="zoom"
      :center="center"
      vid="map"
    >
      <el-amap-marker
        v-for="marker in markers"
        :position="marker.position"
        :key="marker.id"
        :icon="marker.icon"
        :events="marker.events"
        vid="marker"
      ></el-amap-marker>
      <el-amap-text
        v-for="(item, index) in markers"
        :key="index"
        :text="item.devName"
        :position="item.position"
        :offset="offset"
        :events="item.event"
      ></el-amap-text>
      <el-amap-info-window
        v-for="marker in markers"
        :key="marker.ids"
        :ref="marker.ids"
        :position="marker.position"
        :isCustom="true"
        :visible="marker.visible"
        :content="marker.content"
        :events="marker.window_events"
        :closeWhenClickMap="true"
      ></el-amap-info-window>
    </el-amap>
    <!-- <img
      src="@/assets/img/mark1.png"
      alt=""
    > -->
  </div>
</template>
<script>
import { devList } from '@/api/home'
import { pageCamera } from '@/api/project/camera'
import { mapGetters } from 'vuex'
import bar1 from '@/assets/home/m1.png'
import bar2 from '@/assets/home/m2.png'
import bar3 from '@/assets/home/m3.png'
// import bar1 from '@/assets/img/mark2.png'
import guan from '@/assets/home/guan.png'
import ezuikit from '@/utils/ezuikit.js'
import setting from '@/locales/lang/zh-CN/setting'
var EZOPENDemo = null
var EZOPENDemos = null
export default {
  name: 'MapG',
  props: {
    width: {
      type: String || Number,
      default: '1387px'
    },
    height: {
      type: String || Number,
      default: '3%'
    }
  },
  computed: {
    ...mapGetters(['website', 'userInfo'])
  },
  created () {
    window.amap_aaa = this
    this.getList()
  },
  data () {
    return {
      zoom: 4,
      offset: [0, -30],
      center: [116.396304, 39.909847],
      show: true,
      isActive: true,
      camera: [],
      query: {},
      events: {
        init (o) {
          // o.setMapStyle('amap://styles/' + 'c2e02a43ddfa00ccc45907fab357d0f7')
          // setTimeout(() => {
          //   this.drawBounds(o)
          // }, 200)
        }
      },
      markers: [],
      searchOption: {
        city: '',
        citylimit: false
      }
    }
  },
  methods: {
    getMap (query, e) {
      if (e === 1) {
        this.query = query
        this.getList()
      } else {
        this.query = {}
        this.getList()
      }
    },
    getList () {
      devList(this.query).then(res => {
        const that = this
        console.log(res.data, 'kkkkk====')
        const arr = []
        res.data.forEach((v, index) => {
          if (v.log && v.lat) {
            v.position = [v.log, v.lat]
            if (v.onLine === 1) {
              // 在线
              v.icon = bar1
            } else if (v.onLine === 2) {
              // 异常
              v.icon = bar2
            } else {
              // 离线
              v.icon = bar3
            }
            // v.offset = []
            v.ids = index + 'map'
            v.content = '<div class="nesyds">'
            v.content += '<img  class="images" onclick="amap_aaa.handleCose(' + 1 + ')" src="' + guan + '"' + ' > '
            v.content += '<div class="tab"> <div id="devDel" onclick="amap_aaa.handleLook(' + 1 + ')">设备详情</div> <div id="devDel1" onclick="amap_aaa.handleLook(' + 2 + ')">设备视频</div></div >'
            v.content += '<div  id="devCon">'
            v.content += '<div class="textCent">设备名称<span>' + v.devName + '</span></div>'
            v.content += '<div class="textCent">设备地址<span>' + v.addressName + '</span></div>'
            v.content += '<div class="textCent">视频<span>' + v.jkNUm + '</span></div>'
            v.content += '<div class="textCent">设备类型名称<span>' + v.devTypeName + '</span></div>'
            v.content += '<div class="textCent">设备序列号<span>' + v.devCode + '</span></div>'
            v.content += '<div class="textCent">设备状态<div id="devStatus">' + (v.onLine === 1 ? '在线' : v.onLine === 2 ? '异常' : '离线') + '</div></div>'
            v.content += '<div class="textCent">所属部门<span>' + v.tenantName + '</span></div>'
            v.content += '<div class="textCent">故障信息<span>' + (v.faultMsg ? v.faultMsg : '') + '</span></div>'
            v.content += '<div class="textCent">设备模式<span>' + (v.patternType === 1 ? '手动' : '自动') + '</span></div>'
            v.content += '<div class="textCent">本地限速值<span>' + (v.rateLimiting ? v.rateLimiting : 0) + 'km/h</span></div>'
            v.content += '<div class="textCent">过车速度<span>' + (v.nowCarSpeed ? v.nowCarSpeed : '') + 'km/h</span></div>'
            v.content += '<div class="textCent">过车方向<span>' + (v.nowCarDirection ? v.nowCarDirection : '') + '</span></div>'
            v.content += '<div class="textCent">过车时间<span>' + (v.nowCarTime ? v.nowCarTime : '') + '</span></div>'
            v.content += '<div class="textCent">天气信息<span>' + (v.weatherStatus ? '关' : '开') + '</span></div>'
            v.content += '<div class="textCent">待机显示文字<span>' + (v.standbyText ? v.standbyText : '') + '</span></div>'
            v.content += '<div class="textCent">待机显示文字颜色<span>' + (v.standbyTextColourText ? v.standbyTextColourText : '') + '</span></div>'
            v.content += '<div class="textCent">待机文字模式<span>' + (v.standbyTextModel === 1 ? '左移' : v.standbyTextModel === 2 ? '右移' : v.standbyTextModel === 3 ? '上移' : v.standbyTextModel === 1 ? '左移' : v.standbyTextModel === 4 ? '下移' : v.standbyTextModel === 5 ? '闪烁' : '静止') + '</span></div>'
            v.content += '<div class="textCent">待机文字停留时间<span>' + (v.standbyTextWaitTime ? v.standbyTextWaitTime : '') + '</span></div>'
            v.content += '</div>'
            v.content += '<div  id="devKong" class="devKong_d">'
            v.content += '<div id="playWind"></div> <div id="playWinds"></div>'
            v.content += '</div>'
            // v.content += '<div onclick="amap_aaa.bnClick(' + v.deviceCode + ')" class="handle' + ' ' + look + '">查看</div>'
            v.content += '</div>'
            // }, 500);
            v.events = {
              click: () => {
                that.mClick(v)
                console.log(v)
              }
            }
            v.event = {
              click: () => {
                console.log(v, '嘟嘟嘟嘟')
                // this.$router.push()
                this.$router.push({ name: 'Device', params: { devCode: v.devCode } })
                // that.mClick(v)
                // console.log(v)
              }
            }
            v.window_events = {
              close: () => {
                v.visible = false
                that.$forceUpdate()
              }
            }

            v.visible = false
            arr.push(v)
          }
        })
        console.log(arr, 'dddddmmm')
        setTimeout(() => {
          this.markers = arr
        })
      })
    },
    mClick (item) {
      // this.markers.forEach(v => {
      //   // if(v.)
      //   if (v.id == item.id) {
      //     v.visible = true
      //   } else {
      //     v.visible = false
      //   }
      // })

      console.log(item, '大大大大大')
      const query = {
        devId: item.id,
        pageNum: 1,
        pageSize: 3
      }
      pageCamera(query).then(res => {
        console.log(res, 'dddddmmmm')
        this.camera = res.data.records
      })
      this.markers.forEach(v => (v.visible = false))
      item.visible = true
      setTimeout(() => {
        const devStatus = document.getElementById('devStatus')
        const dev = document.getElementById('devCon')
        const devDel = document.getElementById('devDel')
        const devDel1 = document.getElementById('devDel1')
        const devKong = document.getElementById('devKong')
        if (item.onLine === 0) {
          devStatus.style.color = 'red'
        } else if (item.onLine === 1) {
          devStatus.style.color = '#37C88C'
        } else {
          devStatus.style.color = '#edc910'
        }
        dev.style.display = 'block'
        devDel.style.color = '#00F6FF'
        devDel1.style.color = 'rgba(255, 255, 255, 0.49)'
        // const playWind = document.getElementById('playWind')
        // const playWinds = document.getElementById('playWinds')
        devKong.style.display = 'none'
        // playWinds.style.display = 'none'
      }, 10)
      this.$forceUpdate()
    },
    init (lng, lat, show) {
      console.log(show, 'showssss')
      this.show = show
      if (lng && lat) {
        this.center = [lng, lat]
        this.markers = [this.center]
        this.$forceUpdate()
      } else {
        this.center = [116.396304, 39.909847]
      }

      this.$forceUpdate()
    },
    getKong () {
      const that = this
      const num = this.camera.length
      if (num === 0) {

      } else {
        const ezopenInit = () => {
          EZOPENDemo = new EZUIKit.EZUIKitPlayer({
            id: 'playWind',
            width: '250',
            height: '154',
            template: 'standard',
            url: 'ezopen://open.ys7.com/' + that.camera[0].monitorSerial + '/1.live',
            accessToken: that.camera[0].tokenDev
          })
        }
        ezopenInit()
        if (num === 2) {
          const ezopenInit1 = () => {
            EZOPENDemos = new EZUIKit.EZUIKitPlayer({
              id: 'playWinds',
              width: '250',
              height: '154',
              template: 'standard',
              url: 'ezopen://open.ys7.com/' + that.camera[1].monitorSerial + '/1.live',
              accessToken: that.camera[1].tokenDev,
              audio: 1 // 是否默认开启声音 0 - 关闭 1 - 开启
            })
          }
          ezopenInit1()
        }
      }
    },
    handleCose () {
      this.markers.forEach(v => (v.visible = false))
      console.log(1112, '2222222======')
      this.onClose()
      this.$forceUpdate()
    },
    // 关闭摄像头信息
    onClose () {
      const leg = this.camera.length
      if (leg) {
        var stopPromise = EZOPENDemo.stop()
        stopPromise.then((data) => {
          console.log('promise 获取 数据', data)
        })
        // document.getElementById('playWind').innerHTML = ''
        // var destroyPromise = EZOPENDemo.destroy()
        // destroyPromise.then((data) => {
        //   console.log('promise 获取 数据', data)
        // }).catch(err => {
        //   console.log(err, '大大大大大----')
        // })
        EZOPENDemo.stop()
        document.getElementById('playWind').innerHTML = ''
        this.$forceUpdate()
        if (leg === 2) {
          var stopPromises = EZOPENDemos.stop()
          stopPromises.then((data) => {
            console.log('promise 获取 数据', data)
          })
          // var destroyPromises = EZOPENDemos.destroy()
          // destroyPromises.then((data) => {
          //   console.log('promise 获取 数据', data)
          // })
          EZOPENDemos.stop()
          document.getElementById('playWinds').innerHTML = ''
          // EZOPENDemos.stopTalk()
          this.$forceUpdate()
        }
      }
    },
    // 点击设备详情
    handleLook (e) {
      console.log(e, '大大大大大')
      const dev = document.getElementById('devCon')
      const devDel = document.getElementById('devDel')
      const devDel1 = document.getElementById('devDel1')
      const devKong = document.getElementById('devKong')
      // const playWind = document.getElementById('playWind')
      if (e === 1) {
        // playWind.parentNode.removeChild(playWind)
        this.onClose()
        this.isActive = true
        dev.style.display = 'block'
        devKong.style.display = 'none'
        devDel.style.color = '#00F6FF'
        devDel1.style.color = 'rgba(255, 255, 255, 0.49)'
      } else {
        console.log(dev, 'devdevdev')
        // playWind.parentNode.removeChild(playWind)
        // devKong.id = 'playWind'
        this.isActive = false
        dev.style.display = 'none'
        devKong.style.display = 'block'
        devDel1.style.color = '#00F6FF'
        devDel.style.color = 'rgba(255, 255, 255, 0.49)'
        this.getKong()
      }
      this.$forceUpdate()
      console.log(this.isActive, 'isActiveisActiveisActive')
    },
    onSearchResult (pois) {
      let latSum = 0
      let lngSum = 0
      if (pois.length > 0) {
        pois.forEach(poi => {
          const { lng, lat } = poi
          lngSum += lng
          latSum += lat
          this.markers.push([poi.lng, poi.lat])
        })
        const center = {
          lng: lngSum / pois.length,
          lat: latSum / pois.length
        }
        this.center = [center.lng, center.lat]
      }
    }
  }
}
</script>
<style  lang="less">
.search-box {
  position: absolute;
  top: 15px;
  /* left: 6%; */
}

.nesyds {
  /* position: absolute !important; */
  background: rgba(13, 46, 100, 0.8) !important;
  width: 330px !important;
  height: 517px !important;
  border-radius: 7px;
  color: #fff !important;
  padding-top: 18px;
  padding-right: 16px;
  overflow-y: auto;

  /* padding-top: 20px !important; */
  /* top: 0 !important;
  left: 0 !important; */
  .images {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  #devDel {
    color: #00F6FF;
  }

  .tab {
    display: flex;
    align-items: center;
    color: rgba(255, 255, 255, 0.49);
    font-size: 16px;
    padding-left: 21px;

    div {
      margin-right: 27px;
      cursor: pointer;
    }
  }

  .textCent {
    padding-left: 21px;
    // margin-bottom: 15px;
    font-size: 12px;
    padding-top: 8px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    // overflow: hidden !important;
    width: 100%;
    // width: 200px;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;
    // text-align: left;
    // background: red !important;
  }
}

.devKong_d {
  padding-left: 16px;
  padding-top: 20px;

  div {
    width: 300px !important;
  }
}

.amap-icon {
  img {
    top: 20px !important;
  }
}

.amap-box {
  width: 100% !important;
  height: 100% !important;
}

// .icon-move {

// }
/deep/ svg {
  display: none !important;
}

.amap-wrapper {
  width: 100%;
  height: 100%;
}

.amap-overlay-text-container {
  background: transparent !important;
  border: none !important;
  color: #00F6FF !important;
  font-size: 16px !important;
}
</style>
