<template>
  <div class="className" />
</template>

<script>
import echarts from 'echarts' // echarts theme
import { debounce } from '@/utils/util'
require('echarts/theme/macarons')
export default {
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    },
    autoResize: {
      type: Boolean,
      default: true
    },
    chartData: {
      type: Object,
      default: () => { return {} }
    },
    name: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      chart: null
    }
  },
  watch: {
    chartData: {
      deep: true,
      handler (val) {
        this.setOptions(val)
      }
    }
  },
  mounted () {
    this.initChart()
    if (this.autoResize) {
      this.__resizeHandler = debounce(() => {
        if (this.chart) {
          this.chart.resize()
        }
      }, 100)
      window.addEventListener('resize', this.__resizeHandler)
      setTimeout(() => {
        if (this.chart) {
          this.chart.resize()
        }
      }, 600)
    }
  },
  beforeDestroy () {
    if (!this.chart) {
      return
    }
    if (this.autoResize) {
      window.removeEventListener('resize', this.__resizeHandler)
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    setOptions ({ numAll, times } = {}) {
      this.chart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        grid: {
          show: false,
          backgroundColor: 'transparent',
          top: '15%',
          bottom: '18%',
          right: 10,
          left: '5%'
        },
        legend: {
          data: ['过车统计柱状', '过车统计线状'],
          textStyle: {
            color: '#FFFFFF'
          }
        },
        xAxis: [
          {
            type: 'category',
            data: times,
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: {
          type: 'value',
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            color: '#fff'
          },
          splitLine: {
            lineStyle: {
              color: '#44476e',
              type: 'dashed'
            }
          },
          splitArea: {
            show: false
          }
        },
        series: [
          {
            name: '过车统计柱状',
            type: 'bar',
            itemStyle: { // 使用方法二的写法
              color: {
                type: 'linear',
                x: 0, // 右
                y: 0, // 下
                x2: 0, // 左
                y2: 1, // 上
                colorStops: [
                  {
                    offset: 0,
                    color: '#28ccff' // 0% 处的颜色
                  },
                  {
                    offset: 0.7,
                    color: '#1394fc' // 70% 处的颜色
                  },
                  {
                    offset: 1,
                    color: '#0168fa' // 100% 处的颜色
                  }
                ]
              }
            },
            // tooltip: {
            //   valueFormatter: function (value) {
            //     return value + ' ml'
            //   }
            // },
            data: numAll
          },
          {
            name: '过车统计线状',
            type: 'line',
            // yAxisIndex: 1,
            tooltip: {
              valueFormatter: function (value) {
                return value + ' °C'
              }
            },
            data: numAll
          }
        ]
      })
    },
    initChart () {
      this.chart = echarts.init(this.$el, 'macarons')
      this.setOptions(this.chartData)
    }
  }
}
</script>
<style scss scoped>
.className {
  width: 100%;
  height: 95%;
}
</style>
