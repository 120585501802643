import request from '@/utils/request'
// 查询视频列表
export function listCamera (query) {
  return request({
    url: '/gx/gx-Camera/list',
    method: 'get',
    params: query
  })
}
// 查询视频分页
export function pageCamera (query) {
  return request({
    url: '/gx/gx-device-monitor/page',
    method: 'get',
    params: query
  })
}

// 查询视频详细
export function getCamera (data) {
  return request({
    url: '/gx/gx-device-monitor/detail',
    method: 'get',
    params: data
  })
}
// 新增视频
export function addCamera (data) {
  return request({
    url: '/gx/gx-device-monitor/add',
    method: 'post',
    data: data
  })
}
// 修改视频
export function updateCamera (data) {
  return request({
    url: '/gx/gx-device-monitor/edit',
    method: 'post',
    data: data
  })
}

// 删除视频
export function delCamera (data) {
  return request({
    url: '/gx/gx-device-monitor/delete',
    method: 'post',
    data: data
  })
}
