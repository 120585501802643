import request from '@/utils/request'

// 设备列表
export function devList (query) {
  return request({
    url: '/gx/gx-device-dp/dev/list',
    method: 'get',
    params: query
  })
}
// 查询省市区
export function sysTree (query) {
  return request({
    url: '/sys/area/tree',
    method: 'get',
    params: query
  })
}
// 设备排行
export function devPx (query) {
  return request({
    url: '/gx/gx-device-dp/dev/list/px',
    method: 'get',
    params: query
  })
}
// 设备数
export function devNum (query) {
  return request({
    url: '/gx/gx-device-dp/dev/num',
    method: 'get',
    params: query
  })
}
// 实时预警
export function devYj (query) {
  return request({
    url: '/gx/gx-device-dp/dev/yj/list',
    method: 'get',
    params: query
  })
}
// 查询所有的设备
export function getDevice (query) {
  return request({
    url: '/gx/gx-device/list',
    method: 'get',
    params: query
  })
}
// 查询过车统计
export function getDevTj (query) {
  return request({
    url: '/gx/gx-device-record/tj',
    method: 'get',
    params: query
  })
}
// 过车统计
export function carTj (query) {
  return request({
    url: '/gx/gx-device-dp/dev/car/tj',
    method: 'get',
    params: query
  })
}
// 设备视频
export function devJk (query) {
  return request({
    url: '/gx/gx-device-dp/page',
    method: 'get',
    params: query
  })
}
